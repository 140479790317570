import React from 'react'
import { FaPowerOff } from 'react-icons/fa'
import i18n from 'i18next'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { PORTAL_URL } from 'config/config'
import { auth } from '@osrdata/app_core'

import DropdownSNCF, { DROPDOWN_STYLE_TYPES } from './DropdownSNCF'

class LegacyNavBarSNCF extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    main: PropTypes.object.isRequired,
    appName: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    logo: PropTypes.string.isRequired,
  }

  toLogout = () => {
    auth.logout()
  }

  changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  render() {
    const {
      t, appName, logo, user, main,
    } = this.props

    return (
      <div className={`mastheader${main.fullscreen ? ' fullscreen' : ''}`}>
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
        </div>
        <header role="banner" className="mastheader-title d-none d-xl-block">
          <h1 className="text-uppercase text-white pl-3 mb-0">{appName}</h1>
        </header>
        <ul className="mastheader-toolbar toolbar mb-0">
          <li className="toolbar-item separator-gray-500">
            <DropdownSNCF
              titleContent={(
                <>
                  <i className="icons-menu-account icons-size-1x25 icons-md-size-1x5 mr-xl-2" aria-hidden="true" />
                  <span className="d-none d-xl-block">
                    {user.account.firstName}
                    {' '}
                    {user.account.lastName}
                  </span>
                </>
              )}
              type={DROPDOWN_STYLE_TYPES.transparent}
              items={[
                <button
                  type="button"
                  className="btn-link text-reset"
                  onClick={this.toLogout}
                  key="logout"
                >
                  <span className="mr-2"><FaPowerOff /></span>
                  {t('NavBar.disconnect')}
                </button>,
              ]}
            />
          </li>
        </ul>
      </div>
    )
  }
}
const NavBarSNCF = withTranslation()(LegacyNavBarSNCF)

const mapStateToProps = state => ({
  user: state.user,
  main: state.main,
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBarSNCF)
