/* eslint-disable */

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactMapGL, {
  Source,
  Layer,
  Marker,
  Popup as MapPopup,
} from "react-map-gl";
import { updateViewport } from 'reducers/map'
import mapStyleJSON from 'assets/mapstyles/style_osrd_light.json'
import ArmenTronconView from 'components/Map/ArmenTronconView'
import LineGeographicView from 'components/Map/LineGeographicView'
import GeographicView from 'components/Map/GeographicView'
import lamarr from "data/lamarr.json";


const Armen = () => {
  const dispatch = useDispatch()
  const { viewport } = useSelector(state => state.map)
  const [showPopup, togglePopup] = useState(false)
  const [featureInfoHover, setFeatureInfoHover] = useState(undefined)

  const onViewportChange = newViewport => {
    localStorage.setItem('newViewport', JSON.stringify(newViewport))
    dispatch(updateViewport(newViewport))
  }

  const onFeatureClick = e => {
    console.log(e)
    if (e?.features?.length > 0) {
      setFeatureInfoHover(e)
      togglePopup(true)
    }
  }

  function roundNumber(value) {
    return Math.round(value * 100) / 100
  }

  function popupContent() {
    if (featureInfoHover.features[0].layer.id === "armenLayer") {
      return (
        <>
          <div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
            <strong>Méthode : {featureInfoHover.features[0].properties.methode}</strong>

          </div>
          <div className="mapboxgl-popup-container">
          <div className='mt-2'><strong>Urgence pondérée : </strong>{roundNumber(`${featureInfoHover.features[0].properties.urgencePonderee}`)}</div>
            <div className='mb-2'><strong>Longueur du chantier : </strong>{roundNumber(`${featureInfoHover.features[0].properties.longueur}`)}</div>

            <div className='mb-2'><strong>Année de programmation : </strong>{featureInfoHover.features[0].properties.annee}</div>


          </div>

        </>
      )
    } return (
      <>
        <div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
          <strong>Code ligne : {featureInfoHover.features[0].properties.ligne_code}</strong>
          <span>{featureInfoHover.features[0].properties.pkd} / {featureInfoHover.features[0].properties.pkf}</span>

        </div>
        <div className="mapboxgl-popup-container">
          <div><strong>Catégorie : </strong>{featureInfoHover.features[0].properties.categorie}</div>
          <div className='mb-2'><strong>Urgence : </strong>{featureInfoHover.features[0].properties.urgence}</div>

          <div><strong>Année rail corrigée : </strong>{featureInfoHover.features[0].properties.annee_corrigee}</div>
          <div className='mb-2'><strong>Année rail corrigée bis : </strong>{featureInfoHover.features[0].properties.annee_corrigee_bis}</div>

          <div><strong>Libellé de voie : </strong>{featureInfoHover.features[0].properties.lib_voie}</div>
          <div><strong>Infrapole : </strong>{featureInfoHover.features[0].properties.infrapole}</div>


          <div><strong>Profil : </strong>{featureInfoHover.features[0].properties.profil}</div>
          <div className='mb-2'><strong>Groupe UIC : </strong>{featureInfoHover.features[0].properties.uic}</div>
          <div ><strong>Vitesse maxi : </strong>{featureInfoHover.features[0].properties.vpccvi_vitesse_sur_voie_km_h}</div>
        </div>

      </>
    )

  }

  const dataStylePolygon = {
    beforeId: "armentroncon",
    type: "fill",
    paint: {
      "fill-color": {
        property: 'urgencePonderee',
        stops: [
          [200, 'white'],
          [16000, '#0088ce']
        ]
      }
    },
  };


  return (
    <>
      <div style={{ width: '100%', height: 'calc(100vh - 65px)' }}>
        <ReactMapGL
          {...viewport}
          style={{ cursor: 'pointer' }}
          width="100%"
          height="100%"
          mapStyle={mapStyleJSON}
          onViewportChange={onViewportChange}
          onClick={onFeatureClick}
          attributionControl={false}
          clickRadius={2}
          interactiveLayerIds={['armentroncon', 'armenLayer']}
        >
          <GeographicView />
          <ArmenTronconView />
          <Source type="geojson" data={lamarr}>
            <Layer {...dataStylePolygon} id="armenLayer" />
          </Source>

          {showPopup && (<MapPopup
            longitude={featureInfoHover?.lngLat[0]}
            latitude={featureInfoHover?.lngLat[1]}
            closeButton={true}
            captureScroll={true}
            onClose={() => togglePopup(false)}
            className="mapboxgl-hover-custom-popup"
          >{popupContent()}</MapPopup>)}
        </ReactMapGL>

      </div>
    </>
  )
}

export default Armen
