import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'common/BootstrapSNCF/SelectSNCF'
import { GiHistogram } from 'react-icons/gi'
import { TiChartPie } from 'react-icons/ti'
import { AiOutlineAreaChart } from 'react-icons/ai'
import { postWidget } from 'reducers/paa'
import { METRICS } from '../const'

const AddWidgetModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    activeDashboard, representedObject, startDate, endDate, itinerariesFilter, trainsTypesFilter,
    dashboardContentWidth, widgets,
  } = useSelector(state => state.paa)

  const [show, setShow] = useState(false)
  const [widgetName, setWidgetName] = useState()
  const [metric, setMetric] = useState()
  const [representationType, setRepresentationType] = useState()
  const [frequency, setFrequency] = useState()
  const [byTrainType, setByTrainType] = useState('filtre_off')

  const handleNameChange = event => {
    setWidgetName(event.target.value)
  }

  const onSelectMetric = event => {
    setMetric(event.target.value)
  }

  const onRepresentationTypeClick = type => {
    setRepresentationType(type)
  }

  const onSelectFrequency = event => {
    setFrequency(event.target.id)
  }

  const onSelectByTrainType = event => {
    setByTrainType(event.target.id)
  }

  const addWidget = () => {
    setShow(false)
    const params = {
      name: widgetName,
      metric,
      representation_type: representationType,
      start_date: startDate,
      end_date: endDate,
      filtered_objects: itinerariesFilter,
      train_type: trainsTypesFilter,
      by_train_type: byTrainType,
      frequency,
      layout_position: {
        h: 2,
        w: dashboardContentWidth,
        x: 0,
        y: widgets.length === 0 ? 0 : widgets[widgets.length - 1].layout_position.y,
        minW: 3,
        minH: 2,
      },
    }

    dispatch(postWidget(activeDashboard.ID_dashboard, params))
    setWidgetName(undefined)
    setMetric(undefined)
    setRepresentationType(undefined)
    setFrequency(undefined)
    setByTrainType('filtre_off')
  }
  const renderRepresentationTypes = (label, icon, type) => (
    <Col style={{ flexGrow: '0', marginBottom: '1.5rem' }}>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 150 }}
        overlay={(
          <Tooltip id="button-tooltip">
            {label}
          </Tooltip>
)}
      >
        <Button variant={`outline-primary ${representationType === type ? 'representation-button' : ''}`} onClick={() => onRepresentationTypeClick(type)}>{icon}</Button>
      </OverlayTrigger>
    </Col>
  )

  const renderCheckbox = (label, id, onChange, toCheck) => (
    <div key={`custom-inline-checkbox-${id}`} className="mb-4 mr-5">
      <Form.Check
        custom
        inline
        label={label}
        type="checkbox"
        id={id}
        onChange={e => onChange(e)}
        checked={toCheck === id}
      />
    </div>
  )

  return (
    <>
      <div className="d-flex justify-content-end pt-1">
        <Button variant="primary" size="sm" onClick={() => setShow(true)}>{t('Dashboard.addWidget')}</Button>
      </div>
      <Modal
        show={show}
        onExited={() => setShow(false)}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>{t('modals.widgetParams')}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col sm={12} lg={6} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t('modals.name')}</Form.Label>
                  <Form.Control
                    className="input-form"
                    type="text"
                    placeholder={t('modals.widgetPlaceholder')}
                    onChange={handleNameChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} lg={6} xl={6}>
                <Select label={t('modals.metric')} options={METRICS[representedObject]} onChange={e => onSelectMetric(e)} />
              </Col>
              <Col sm={12} lg={6} xl={6}>
                <Form.Label>{t('modals.representationType')}</Form.Label>
                <Row>
                  {renderRepresentationTypes('Diagramme en bâtons', <GiHistogram style={{ width: '2rem', height: '2rem' }} />, 'hbar')}
                  {renderRepresentationTypes('Diagramme circulaire', <TiChartPie style={{ width: '2rem', height: '2rem' }} />, 'piechart')}
                  {renderRepresentationTypes('Aires empilées', <AiOutlineAreaChart style={{ width: '2rem', height: '2rem' }} />, 'line')}
                </Row>
              </Col>
              { representedObject === 'circulation' && (
              <Col sm={12} lg={6} xl={6}>
                <Form.Label className="mb-3">{t('modals.frequency')}</Form.Label>
                <Row className="ml-1">
                  {renderCheckbox('Par jour', 'daily', onSelectFrequency, frequency)}
                  {renderCheckbox('Toute la période', 'all', onSelectFrequency, frequency)}
                </Row>
              </Col>
              )}
              { representedObject === 'itineraire' && (
              <Col sm={12} lg={6} xl={6}>
                <Form.Label className="mb-3">{t('modals.by_train_type')}</Form.Label>
                <Row className="ml-1">
                  {renderCheckbox('Oui', 'filter_on', onSelectByTrainType, byTrainType)}
                  {renderCheckbox('Non', 'filter_off', onSelectByTrainType, byTrainType)}
                </Row>
              </Col>
              )}
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" size="sm" onClick={() => setShow(false)}>
            {t('modals.cancelButton')}
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={!widgetName || !metric || !representationType}
            onClick={addWidget}
          >
            {t('modals.addButton')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}
export default AddWidgetModal
