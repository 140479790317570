import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'
import {
  Button, Card, Col, Form, Modal,
} from 'react-bootstrap'
import { MdAdd, MdEdit } from 'react-icons/md'
import { patchDashboard, postDashboard } from 'reducers/paa'
import { useTranslation } from 'react-i18next'

const AddDashboardModal = ({ defaultValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeZone, representedObject } = useSelector(state => state.paa)

  const [show, setShow] = useState(false)
  const [dashboardName, setDashboardName] = useState()

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const addDashboard = () => {
    const token = localStorage.getItem('access_token')
    const decodedToken = jwtDecode(token)
    const params = {
      name: dashboardName,
      users: [
        {
          ID_user: decodedToken.sub,
        },
      ],
      zone: activeZone,
      represented_object: representedObject,
    }

    dispatch(postDashboard(params))
    setShow(false)
    setDashboardName(undefined)
  }

  const updateDashboard = () => {
    const params = {
      name: dashboardName,
    }

    dispatch(patchDashboard(defaultValue.ID_dashboard, params))
    setShow(false)
    setDashboardName(undefined)
  }

  const handleNameChange = event => {
    setDashboardName(event.target.value)
  }

  return (
    <>
      {defaultValue.name === undefined ? (
        <Card as="button" className="text-center p-0 card-button" style={{ height: '7rem', width: '7rem', minHeight: '0' }} onClick={handleShow}>
          <Card.Body className="d-flex align-items-center justify-content-center">
            <Card.Text><MdAdd /></Card.Text>
          </Card.Body>
        </Card>
      )
        : (
          <Button
            size="sm"
            className="btn-transparent mx-1"
            style={{ color: '#0088ce', minWidth: '0.875rem', minHeight: '0.875rem' }}
            onClick={handleShow}
          >
            <MdEdit />
          </Button>
        )}
      <Modal
        show={show}
        onExited={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>{t('modals.dashboardParams')}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t('modals.name')}</Form.Label>
                  <Form.Control style={{ minHeight: '2.4rem', height: '2.4rem', fontSize: '.95rem' }} type="text" placeholder={t('modals.dashboardPlaceholder')} defaultValue={defaultValue.name} onChange={handleNameChange} />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {t('modals.cancelButton')}
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={!dashboardName}
            onClick={defaultValue.name === undefined ? addDashboard : updateDashboard}
          >
            {defaultValue.name === undefined ? t('modals.addButton') : t('modals.editButton')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

AddDashboardModal.propTypes = {
  defaultValue: PropTypes.object,
}

AddDashboardModal.defaultProps = {
  defaultValue: {},
}

export default AddDashboardModal
