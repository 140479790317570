/**
 * Fonction de callback lors d'un requête vers une URL extérieure
 * @param {string} url - L'URL de la requête
 * @param {string} resourceType - Le type de ressource
 * @param {string} urlmap - L'URL de base pour la carte
 * @returns {(object| null)} Le token d'authentifcation lorsqu'il est nécessaire
 */

import { MAIN_API } from 'config/config'

export const normalizeString = str => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

/**
 * Filter an array of string by element's name or by string element itself, using a search value
 * @param {array} array - Array to filter
 * @param {string} search - Search term
 * @returns {array} Filtered array
 */
export function filterBySearch(array, search) {
  return array.filter(element => normalizeString(element[0]).includes(normalizeString(search)))
}

const isTileserverResource = type => (type === 'Source' || type === 'Tile')

const replaceUrl = url => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

export const transformRequest = (url, resourceType) => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}
export const getSignalLayerId = type => `signal_${type.toLowerCase().replace(/ |\./g, '_')}`
