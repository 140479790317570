import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postDashboard } from 'reducers/app'
import jwtDecode from 'jwt-decode'
import {
  Button, Col, Form, Modal,
} from 'react-bootstrap'

const AddDashboardModal = () => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [dashboardName, setDashboardName] = useState()

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const addDashboard = () => {
    const token = localStorage.getItem('access_token')
    const decodedToken = jwtDecode(token)
    const params = {
      name: dashboardName,
      users: [
        {
          ID_user: decodedToken.sub,
        },
      ],
    }
    dispatch(postDashboard(params))
    setShow(false)
  }

  const handleNameChange = event => {
    setDashboardName(event.target.value)
  }

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Ajouter un tableau de bord
      </Button>
      <Modal
        show={show}
        onExited={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>Paramètres du tableau de bord</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control type="text" placeholder="Nom du tableau bord" onChange={handleNameChange} />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={!dashboardName}
            onClick={addDashboard}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddDashboardModal
