import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const MastNavSNCF = ({
  items, itemsBottom,
}) => {
  const main = useSelector(state => state.main)

  return (
    <nav role="navigation" className={`mastnav${main.fullscreen ? ' fullscreen' : ''}`}>
      <ul className="mastnav-top">
        {items}
      </ul>
      {itemsBottom}
    </nav>
  )
}

MastNavSNCF.propTypes = {
  items: PropTypes.object.isRequired,
  itemsBottom: PropTypes.object,
}

MastNavSNCF.defaultProps = {
  itemsBottom: <></>,
}

export default MastNavSNCF
