/* eslint-disable */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateWidgetList, addWidgetToDashboard } from "reducers/app";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import moment from "moment";
import "moment/locale/fr";
import DatePickerSNCF, {
  PICKER_MODES,
} from "common/BootstrapSNCF/DatePickerSNCF/DatePickerSNCF";
import nextId from "react-id-generator";

const AddWidgetModal = (props) => {
  const dispatch = useDispatch();

  const [lists, setLists] = useState([]);
  const [show, setShow] = useState(false);

  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [operationType, setOperationType] = useState();
  const [spacialScale, setSpacialScale] = useState();
  const [responsability, setResponsability] = useState();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const addWidget = () => {
    setShow(false);
    const params = {
      name: nextId(),
      start_date: moment(startDate).format(moment.HTML5_FMT.DATE),
      end_date: moment(endDate).format(moment.HTML5_FMT.DATE),
      data: "incidents",
      operation_type: operationType,
      spatial_scale: spacialScale,
      responsabilite_regularite: responsability,
      layout_position: {
        h: 2,
        w: 5,
        x: 0,
        y: 0,
        minW: 3,
        minH: 2,
        moved: false,
        static: false,
      },
    };

    dispatch(addWidgetToDashboard(props.dashboardID, params));
  };
  const onSelectOperationType = (event) => {
    setOperationType(event);
  };
  const onSelectSpacialScale = (event) => {
    setSpacialScale(event);
  };
  const onSelectResponsability = (event) => {
    setResponsability(event);
  };
  return (
    <>
      <button
        type="button"
        className={`btn mr-3 btn-sm btn-light`}
        onClick={handleShow}
      >
        Ajouter un widget
      </button>

      <Modal
        show={show}
        onExited={handleClose}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>Paramètres du widget</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
            <Col sm={12} lg={6} xl={6}>
                <Form.Group controlId="formGridState1">
                  <Form.Label>Type d'opération</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="default"
                    onChange={(e) => onSelectOperationType(e.target.value)}
                  >
                    <option value="default" disabled>
                      Choisir...
                    </option>
                    <option value="mtbf">MTBF</option>
                    <option value="count">Total</option>
                    <option value="average">Moyenne</option>
                    <option value="variance">Variance</option>
                    <option value="max">Maximum</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={12} lg={6} xl={6}>
                <Form.Group controlId="formGridState2">
                  <Form.Label>Type de segment</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="default"
                    onChange={(e) => onSelectSpacialScale(e.target.value)}
                  >
                    <option value="default" disabled>
                      Choisir...
                    </option>
                    <option value="segment_strategique">
                      Segment stratégique
                    </option>
                    <option value="1km_segment">Segment 1km</option>
                    <option value="5km_segment">Segment 5kms</option>
                    <option value="10km_segment">Segment 10kms</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {/* <Col sm={12} xl={4}>
                <Form.Group controlId="formGridState3">
                  <Form.Label>Résponsabilité</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="default"
                    onChange={(e) => onSelectResponsability(e.target.value)}
                  >
                    <option value="default" disabled>
                      Choisir...
                    </option>
                    <option value="Cause GI">Cause GI</option>
                    <option value="Hors GI">Hors GI</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col sm={12} lg={6} xl={6}>
                <DatePickerSNCF
                  mode={PICKER_MODES.single}
                  date=""
                  onChange={(date) => setStartDate(date[0])}
                  onClear={setStartDate}
                  placeholder="Date de début"
                  dateFormat="d/m/Y"
                  label="Date de début"
                  enableTime
                />
              </Col>
              <Col sm={12} lg={6} xl={6}>
                <DatePickerSNCF
                  mode={PICKER_MODES.single}
                  date=""
                  onChange={(date) => setEndDate(date[0])}
                  onClear={setEndDate}
                  placeholder="Date de fin"
                  dateFormat="d/m/Y"
                  label="Date de fin"
                  enableTime
                />
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={!endDate || !startDate || !operationType || !spacialScale}
            onClick={addWidget}
          >
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddWidgetModal;
