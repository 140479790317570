import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboards, setActiveDashboard, updateDashboardContentWidth, updateRepresentedObject,
} from 'reducers/paa'
import jwtDecode from 'jwt-decode'
import DashboardContent from '../components/DashboardContent'
import DashboardSidebar from '../components/DashboardSidebar'
import FilterSidebar from '../components/FilterSidebar'
import { defaultDashboards } from '../const'

const DashboardPAA = ({ category }) => {
  const dispatch = useDispatch()
  const {
    dashboards, activeZone, representedObject,
    dashboard, deletedDashboard, activeDashboard, showDashboards, showFilters,
  } = useSelector(state => state.paa)

  useEffect(() => {
    dispatch(updateRepresentedObject(category))
    dispatch(setActiveDashboard((defaultDashboards[category].filter(board => board.name === 'Vue de synthèse'))[0]))
  }, [category])

  useEffect(() => {
    const token = localStorage.getItem('access_token')
    const decodedToken = jwtDecode(token)
    const params = {
      userID: decodedToken.sub,
      zone: activeZone,
      objet: representedObject,
    }
    dispatch(getDashboards(params))
  }, [representedObject, dashboard, deletedDashboard])

  const dashboardsList = defaultDashboards[representedObject].concat(dashboards)

  const onSize = size => {
    dispatch(updateDashboardContentWidth(Math.trunc(size.width)))
  }

  return (
    <div className="d-flex dashboard">
      <div>{showDashboards && <DashboardSidebar />}</div>
      <div style={{ width: '100%' }}>
        {dashboardsList.map(board => (
          board.ID_dashboard === activeDashboard.ID_dashboard
           && <DashboardContent key={board.ID_dashboard} onSize={onSize} />
        ))}
      </div>
      <div>{showFilters && <FilterSidebar />}</div>
    </div>
  )
}

DashboardPAA.propTypes = {
  category: PropTypes.string,
}

DashboardPAA.defaultProps = {
  category: 'itineraire',
}

export default DashboardPAA
