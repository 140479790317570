import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Nav, Row, Tab,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  getWidgets, updateShowDashboards, updateShowFilters, updateEndDate,
  updateItinerariesFilter, updateStartDate, updateTrainsTypesFilter, patchWidget,
} from 'reducers/paa'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown } from 'react-icons/io'
import { withSize } from 'react-sizeme'
import AddWidgetModal from './AddWidgetModal'
import { defaultWidgets, TrainsTypes } from '../const'
import WidgetPAA from './WidgetPAA'

const ResponsiveGridLayout = WidthProvider(Responsive)

const DashboardContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    showDashboards, showFilters, representedObject, activeDashboard, widgets, deletedWidget, widget,
    itinerariesList, dashboardContentWidth, showLoader, error,
  } = useSelector(state => state.paa)
  const [showContent, setShowCentent] = useState(false)

  useEffect(() => {
    setShowCentent(true)
  }, [dashboardContentWidth, activeDashboard])

  useEffect(() => window.addEventListener('resize', () => setShowCentent(!showContent)), [dashboardContentWidth])

  useEffect(() => {
    if (!activeDashboard.isDefault) dispatch(getWidgets(activeDashboard.ID_dashboard))
  }, [activeDashboard, deletedWidget, widget])

  useEffect(() => {
    if (!activeDashboard.isDefault && widgets.length !== 0) {
      dispatch(updateStartDate(widgets[0].start_date))
      dispatch(updateEndDate(widgets[0].end_date))
      dispatch(updateItinerariesFilter(widgets[0].filtered_objects))
      dispatch(updateTrainsTypesFilter(widgets[0].train_type))
    } else {
      dispatch(updateStartDate('2019-01-01'))
      dispatch(updateEndDate('2019-01-31'))
      const itemsList = []
      itinerariesList.forEach(value => itemsList.push(value[0]))
      dispatch(updateItinerariesFilter(itemsList))
      dispatch(updateTrainsTypesFilter(TrainsTypes))
    }
  }, [activeDashboard, widget, deletedWidget])

  useEffect(() => {
    setShowCentent(!showContent)
  }, [showFilters, showDashboards])

  const getWidgetLayout = (layouts, layout) => {
    const sameLayoutAbscissa = layouts.filter(l => l.y === layout.y
       && l.x !== layout.x)
    const widgetLayout = sameLayoutAbscissa.length === 0
      ? { ...layout, w: dashboardContentWidth }
      : layout
    return widgetLayout
  }

  const onLayoutChange = layout => {
    if (!activeDashboard.isDefault) {
      layout.forEach(l => {
        dispatch(patchWidget(activeDashboard.ID_dashboard, l.i, {
          layout_position: getWidgetLayout(layout, l),
        }, true))
      })
    }
  }

  const widgetsList = activeDashboard.isDefault ? defaultWidgets[representedObject]
    .filter(w => w.dashboard === activeDashboard.ID_dashboard) : widgets

  return (
    <Tab.Container defaultActiveKey="graphique">
      <Row style={{ width: '100%' }} className="ml-0">
        {!showDashboards && (
          <Col sm={1} className="p-0" style={{ maxWidth: '5%', height: '2%' }}>
            <Button size="sm" className="btn-transparent" style={{ color: 'black' }} onClick={() => dispatch(updateShowDashboards(true))}>
              <FaAngleDoubleRight />
            </Button>
          </Col>
        )}
        <Col className="d-flex justify-content-between">
          <Nav variant="tabs" className="pt-1">
            <Nav.Item className="px-2">
              <Nav.Link eventKey="graphique">{t('Dashboard.graphic')}</Nav.Link>
            </Nav.Item>
            <Nav.Item className="px-2">
              <Nav.Link eventKey="carto">{t('Dashboard.carto')}</Nav.Link>
            </Nav.Item>
          </Nav>
          <AddWidgetModal />
        </Col>
        {!showFilters && (
        <Col sm={1} className="d-flex flex-row-reverse p-0" style={{ maxWidth: '5%', height: '2%' }}>
          <Button size="sm" className="btn-transparent" style={{ color: 'black' }} onClick={() => dispatch(updateShowFilters(true))}>
            <FaAngleDoubleLeft />
          </Button>
        </Col>
        )}
      </Row>
      <Tab.Content className="px-2 pt-2" style={{ overflowY: 'auto', height: 'calc(100vh - 5.9rem)' }}>
        <Tab.Pane eventKey="graphique">
          {showLoader && (
          <Alert className="alert-loading" variant="secondary">
            {t('common.loading')}
          </Alert>
          )}
          {error && (
          <Alert variant="danger">
            {t('common.error')}
          </Alert>
          )}
          {showContent
          && (
          <ResponsiveGridLayout
            className="layout"
            autoSize
            measureBeforeMount
            useCSSTransforms
            onLayoutChange={onLayoutChange}
          >
            {widgetsList.map(widg => (
              <div
                key={widg.ID_widget}
                data-grid={widg.layout_position}
              >
                <WidgetPAA params={widg} />
                <div className="widget-resize">
                  <IoIosArrowDown />
                </div>
              </div>
            ))}
          </ResponsiveGridLayout>
          )}
        </Tab.Pane>
        <Tab.Pane eventKey="carto">
          carto
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default withSize()(DashboardContent)
