import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button, Col, Form, Modal,
} from 'react-bootstrap'
import { MdEdit } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { patchWidget } from 'reducers/paa'

const UpdateWidgetNameModal = ({ defaultValue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeDashboard } = useSelector(state => state.paa)

  const [show, setShow] = useState(false)
  const [widgetName, setWidgetName] = useState()

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const updateWidget = () => {
    const params = {
      name: widgetName,
    }

    dispatch(patchWidget(activeDashboard.ID_dashboard, defaultValue.ID_widget, params))
    setShow(false)
    setWidgetName(undefined)
  }

  const handleNameChange = event => {
    setWidgetName(event.target.value)
  }

  return (
    <>
      <Button
        size="sm"
        className="btn-transparent mx-1"
        style={{ color: '#0088ce', minWidth: '0.875rem', minHeight: '0.875rem' }}
        onClick={handleShow}
      >
        <MdEdit />
      </Button>
      <Modal
        show={show}
        onExited={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>{t('modals.widgetParams')}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t('modals.name')}</Form.Label>
                  <Form.Control style={{ minHeight: '2.4rem', height: '2.4rem', fontSize: '.95rem' }} type="text" placeholder={t('modals.widgetPlaceholder')} defaultValue={defaultValue.name} onChange={handleNameChange} />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {t('modals.cancelButton')}
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={!widgetName}
            onClick={updateWidget}
          >
            {t('modals.editButton')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

UpdateWidgetNameModal.propTypes = {
  defaultValue: PropTypes.object,
}

UpdateWidgetNameModal.defaultProps = {
  defaultValue: {},
}

export default UpdateWidgetNameModal
