import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import PieChart from './PieChart'
import Histogram from './Histogram'
import { API_URLS, buttomAxiosLegend, TrainsTypes } from '../const'
import Line from './Line'

const WidgetPAA = ({ params }) => {
  const { t } = useTranslation()
  const {
    activeZone, representedObject, activeDashboard, widget, applyParams, error,
  } = useSelector(state => state.paa)
  const [widgetData, setWidgetData] = useState(undefined)
  const [showLoader, setShowLoader] = useState(true)
  const [loadingError, setLoadingError] = useState(false)

  useEffect(() => {
    const updateWidget = widget && widget.dashboard === activeDashboard.ID_dashboard
    const startDate = updateWidget ? widget.start_date : params.start_date
    const endDate = updateWidget ? widget.end_date : params.end_date
    const filteredObjects = updateWidget ? widget.filtered_objects : params.filtered_objects
    const trainsTypes = updateWidget ? widget.train_type : params.train_type
    setLoadingError(false)
    fetch(
      `https://gateway.dev.dgexsol.fr${API_URLS[representedObject]}?metric=${encodeURIComponent(
        params.metric,
      )}&start_date=${encodeURIComponent(
        startDate,
      )}&end_date=${encodeURIComponent(
        endDate,
      )}&zone=${encodeURIComponent(
        activeZone,
      )}&filtered_objects=${encodeURIComponent(
        filteredObjects,
      )}&train_type=${encodeURIComponent(
        trainsTypes,
      )}&by_train_type=${encodeURIComponent(
        params.by_train_type,
      )}&frequency=${encodeURIComponent(
        params.frequency,
      )}
        `,
      {
        method: 'GET',
        headers: {
          Authorization:
            `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    )
      .then(resp => resp.json())
      .then(json => {
        setWidgetData(json)
        setShowLoader(false)
      })
      .catch(() => {
        setShowLoader(false)
        setLoadingError(true)
      })
  }, [representedObject, activeDashboard, widget])

  useEffect(() => {
    if (widget.ID_widget === params.ID_widget) setShowLoader(true)
  }, [representedObject, activeDashboard, applyParams])

  if (showLoader && !error) {
    return (
      <div className="dataLoader" id="dataLoader">
        {t('common.loading')}
      </div>
    )
  }

  if (loadingError) {
    return (
      <div className="dataLoader" id="dataLoader">
        <div>{t('common.errorNotice')}</div>
      </div>
    )
  }

  switch (params.representation_type) {
    case 'piechart':
      return (
        <>
          {widgetData.length !== 0 && (
          <PieChart
            data={widgetData}
            header={params.name}
            widget={params}
            isDefault={params.is_default}
          />
          )}
        </>
      )
    case 'hbar':
      return (
        <>
          {widgetData.length !== 0 && (
          <Histogram
            data={widgetData.slice(widgetData.length - 9, widgetData.length)}
            keys={widgetData[0].value === undefined ? TrainsTypes : ['value']}
            buttomAxiosLegend={buttomAxiosLegend[representedObject][params.metric]}
            leftAxiosLegend="itineraire"
            header={params.name}
            colorBy={widgetData[0].value === undefined ? 'id' : 'indexValue'}
            widget={params}
            isDefault={params.is_default}
          />
          )}
        </>
      )
    case 'line':
      return (
        <>
          {widgetData.length !== 0 && (
          <Line
            data={widgetData}
            header={params.name}
            widget={params}
            isDefault={params.is_default}
            buttomAxiosLegend={representedObject === 'itineraire' ? 'Jour' : 'Heure'}
          />
          )}
        </>
      )
    default:
      return (
        <>
        </>
      )
  }
}

WidgetPAA.propTypes = {
  params: PropTypes.object.isRequired,
}

export default WidgetPAA
