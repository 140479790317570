import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { MAP_URL } from 'common/Map/const'
import { geoMainLayer, geoServiceLayer } from 'common/Map/Layers/geographiclayers.json'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'

function GeographicView() {
  return (
    <Source
      type="vector"
      url={`${MAP_URL}/chartis/layer/map_midi_tronconditinerairevoie/mvt/geo/`}
      source-layer={OBJECTS_LAYERS.tivGeo}
    >
      <Layer
        {...geoMainLayer}
        layout={{ visibility: 'visible' }}
        source-layer={OBJECTS_LAYERS.tivGeo}
      />
      <Layer
        {...geoServiceLayer}
        layout={{ visibility: 'visible' }}
        source-layer={OBJECTS_LAYERS.tivGeo}
      />
    </Source>
  )
}

export default GeographicView
