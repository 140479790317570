import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import { ResponsivePie } from '@nivo/pie'
import WidgetTools from './WidgetTools'

const PieChart = ({
  header, data, widget, isDefault,
}) => (
  <Card className="widget-card">
    <Card.Header className="d-flex justify-content-between pr-2">
      {header}
      {!isDefault && <WidgetTools widget={widget} />}
    </Card.Header>
    <Card.Body style={{ height: '250px' }}>
      <ResponsivePie
        data={data}
        id="key"
        margin={{
          top: 20, right: 40, bottom: 30, left: 70,
        }}
        colors={{ scheme: 'blues' }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#b6b3b3"
        theme={{
          fontSize: 12,
          axis: {
            legend: {
              text: {
                fontSize: 15,
              },
            },
          },
          labels: {
            text: {
              fontSize: 15,
            },
          },
        }}
      />
    </Card.Body>
  </Card>
)

PieChart.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  widget: PropTypes.object.isRequired,
  isDefault: PropTypes.bool.isRequired,
}

export default PieChart
