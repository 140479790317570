/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMapGL, {
  Source,
  Layer,
  Marker,
  Popup as MapPopup,
} from "react-map-gl";
import { getWidgetData, deleteWidget } from "reducers/app";

import mapStyleJSON from "assets/mapstyles/style_osrd_light.json";
import line from "data/line.json";
import pr from "data/pr.json";
import "./Widget.scss";
import nextId from "react-id-generator";

function Widget({ params }) {
  const dispatch = useDispatch();
  const [showPrs, setShowPrs] = useState(false);
  const [showLines, setShowLines] = useState(false);
  const [showIncidents, setShowIncidents] = useState(false);
  const [showIncidentsLayer, setShowIncidentsLayer] = useState("none");
  const [showPrsLayer, setShowPrsLayer] = useState("none");

  const [info, setInfo] = useState(undefined);
  const [widgetData, setWidgetData] = useState(undefined);
  const [showLoader, setShowLoader] = useState(true);
  const [showPopup, togglePopup] = useState(false);
  const [showPopupPr, togglePopupPr] = useState(false);

  const [popupData, setPopupData] = useState(undefined);
  const [loadingError, setLoadingError] = useState(false);

  const toggleLines = () => {
    setShowLines(!showLines);
  };
  const togglePRs = () => {
    if (showPrsLayer === "visible") {
      setShowPrsLayer("none");
    } else {
      setShowPrsLayer("visible");
    }
  };
  const toggleIncidents = () => {
    if (showIncidentsLayer === "visible") {
      setShowIncidentsLayer("none");
    } else {
      setShowIncidentsLayer("visible");
    }
  };

  function statusColor(status) {
    switch (status) {
      case 0.05:
        return "#00cd00";
      case 0.25:
        return "#1b8a5a";
      case 0.5:
        return "#fbb021";
      case 0.75:
        return "#f68838";
      case 0.95:
        return "#ee3e32";
      default:
        return "#000";
    }
  }

  function statusColorMtbf(status) {
    switch (status) {
      case 0.05:
        return "#ee3e32";
      case 0.25:
        return "#f68838";
      case 0.5:
        return "#fbb021";
      case 0.75:
        return "#1b8a5a";
      case 0.95:
        return "#00cd00";
      default:
        return "#000";
    }
  }

  const [viewport, setViewport] = useState({
    latitude: 48.888114,
    longitude: 2.364995,
    zoom: 7.4016,
    bearing: 0,
    pitch: 0,
  });

  const dataStyleIncident = {
    type: "line",
    paint: {
      "line-width": {
        base: 2,
        stops: [
          [6, 2],
          [12, 2],
          [22, 2],
        ],
      },
      "line-color": [
        "case",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[0].limit,
          ],
        ],
        "#00cd00",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[1].limit,
          ],
        ],
        "#1b8a5a",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[2].limit,
          ],
        ],
        "#fbb021",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[3].limit,
          ],
        ],
        "#f68838",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[4].limit,
          ],
        ],
        "#f68838",
        [
          ">",
          ["get", "aggregated_incidents"],
          widgetData?.percentiles[4].limit,
        ],
        "#ee3e32",
        "grey",
      ],
    },
  };

  const dataStyleIncidentMTBF = {
    type: "line",
    paint: {
      "line-width": {
        base: 2,
        stops: [
          [6, 2],
          [12, 2],
          [22, 2],
        ],
      },
      "line-color": [
        "case",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[0].limit,
          ],
        ],
        "#ee3e32",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[1].limit,
          ],
        ],
        "#f68838",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[2].limit,
          ],
        ],
        "#f68838",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[3].limit,
          ],
        ],
        "#fbb021",
        [
          "all",
          [
            "<=",
            ["get", "aggregated_incidents"],
            widgetData?.percentiles[4].limit,
          ],
        ],
        "#1b8a5a",
        [
          ">",
          ["get", "aggregated_incidents"],
          widgetData?.percentiles[4].limit,
        ],
        "#00cd00",
        "grey",
      ],
    },
  };

  const dataStyleLine = {
    beforeId: "incidentsLayer",
    type: "line",
    paint: {
      "line-color": "#0088ce",
      "line-width": {
        base: 3,
        stops: [
          [6, 1],
          [12, 1],
          [22, 1],
        ],
      },
    },
  };

  const dataStylePr = {
    type: "circle",
    paint: {
      "circle-radius": {
        base: 1,
        stops: [
          [6, 1],
          [12, 4],
          [22, 30],
        ],
      },
      "circle-color": "#000000",
    },
  };

  useEffect(() => {
    setLoadingError(false);
    setShowLoader(true);
    fetch(
      `https://gateway.dev.dgexsol.fr/usage_reseau/incidents/?spatial_scale=${encodeURIComponent(
        params.spatial_scale
      )}&operation_type=${encodeURIComponent(
        params.operation_type
      )}&start_time=${encodeURIComponent(
        params.start_date
      )}&end_time=${encodeURIComponent(params.end_date)}&percentiles[]=0.05
      &percentiles[]=0.25
      &percentiles[]=0.50
      &percentiles[]=0.75
      &percentiles[]=0.95
      `,
      {
        method: "GET",
        headers: {
          Authorization:
          `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        setWidgetData(json),
          setShowIncidents(true),
          setShowIncidentsLayer("visible"),
          setShowPrs(true);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        setLoadingError(true);
      });
  }, []);

  const onFeatureHover = (e) => {
    console.log(e);
    if (e?.features?.length > 0 && e?.features[0]?.layer?.id === "prsLayer") {
      setPopupData(e);
      togglePopupPr(true);
      togglePopup(false);
    }
    if (
      e?.features?.length > 0 &&
      e?.features[0]?.layer?.id === "incidentsLayer"
    ) {
      setPopupData(e);
      togglePopupPr(false);
      togglePopup(true);
    }
  };

  const deleteWidgetFromDashboard = (params) => {
    dispatch(deleteWidget(params.dashboard, params.ID_widget));
  };

  function round(input) {
    const output = Math.round(input * 100) / 100;
    return output;
  }
  function round2(input) {
    const output = Math.round(input);
    return output;
  }

  const PopupLine = ({ label, value }) => (
    <div>
    <strong>{label} </strong>
    {value}
  </div>
  );

  return (
    <>
      <ReactMapGL
        {...viewport}
        style={{ cursor: "pointer" }}
        width="100%"
        height="100%"
        mapStyle={mapStyleJSON}
        onViewportChange={setViewport}
        onClick={onFeatureHover}
        attributionControl={false}
        interactiveLayerIds={["prsLayer", "incidentsLayer"]}
        clickRadius={10}
      >
        {showLines && (
          <Source type="geojson" data={line}>
            <Layer {...dataStyleLine} id="lineLayer" />
          </Source>
        )}
        {showPrs && (
          <Source type="geojson" data={pr}>
            <Layer
              {...dataStylePr}
              id="prsLayer"
              layout={{ visibility: showPrsLayer }}
            />
          </Source>
        )}
        {params.operation_type === "mtbf" && showIncidents && (
          <Source type="geojson" data={widgetData?.results}>
            <Layer
              {...dataStyleIncidentMTBF}
              id="incidentsLayer"
              layout={{ visibility: showIncidentsLayer }}
            />
          </Source>
        )}
        {params.operation_type !== "mtbf" && showIncidents && (
          <Source type="geojson" data={widgetData?.results}>
            <Layer
              {...dataStyleIncident}
              id="incidentsLayer"
              layout={{ visibility: showIncidentsLayer }}
            />
          </Source>
        )}
        {showPopup && (
          <MapPopup
            latitude={popupData?.lngLat[1]}
            longitude={popupData?.lngLat[0]}
            closeButton={true}
            closeOnClick={false}
            onClose={() => togglePopup(false)}
            className="mapboxgl-hover-custom-popup"
          >
            <div
              className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}
            >
              Code Ligne: {popupData?.features[0]?.properties?.code_ligne}
            </div>
            <div className="mapboxgl-popup-container">
              {params.operation_type === "mtbf" && (
                <PopupLine label="Temps moyen inter-incidents:" value={round(
                  popupData?.features[0]?.properties?.aggregated_incidents
                )} />
              )}
              {<PopupLine label="Centile:" value={round(popupData?.features[0]?.properties?.percentile)} />}
              {params.operation_type ===
                ("count" || "average" || "variance" || "max") && (
                  <PopupLine label="Nombre d'incident(s):" value={round(
                    popupData?.features[0]?.properties?.aggregated_incidents
                  )} />
              )}         
              {<PopupLine label="Id segment:" value={popupData?.features[0]?.properties?.segment_id} />}
              {<PopupLine label="Classe stratégique:" value={popupData?.features[0]?.properties?.classe_strategique} />}
              {<PopupLine label="Libelle segment début:" value={popupData?.features[0]?.properties?.libelle_seg_debut} />}
              {<PopupLine label="Libelle segment fin:" value={popupData?.features[0]?.properties?.libelle_seg_fin} />}
              {<PopupLine label="Pk début:" value={popupData?.features[0]?.properties?.PK_debut} />}
              {<PopupLine label="Pk fin:" value={popupData?.features[0]?.properties?.PK_fin} />}
              {<PopupLine label="Nb TGV par jour:" value={round(popupData?.features[0]?.properties?.TGV)} />}
              {<PopupLine label="Nb TER par jour:" value={round(popupData?.features[0]?.properties?.TER)} />}
              {<PopupLine label="Nb IC par jour:" value={round(popupData?.features[0]?.properties?.IC)} />}
              {<PopupLine label="Nb IDF par jour:" value={round(popupData?.features[0]?.properties?.IDF)} />}
              {<PopupLine label="Nb Fret par jour:" value={round(popupData?.features[0]?.properties?.fret)} />}
              {<PopupLine label="Type de traffic:" value={popupData?.features[0]?.properties?.type_traffic} />}
              {<PopupLine label="Type de flux:" value={popupData?.features[0]?.properties?.type_flux} />}
              {<PopupLine label="Type ambition:" value={popupData?.features[0]?.properties?.type_ambition} />}
            </div>
          </MapPopup>
        )}
        {showPopupPr && (
          <MapPopup
            latitude={popupData?.lngLat[1]}
            longitude={popupData?.lngLat[0]}
            closeButton={true}
            closeOnClick={false}
            onClose={() => togglePopupPr(false)}
            className="mapboxgl-hover-custom-popup"
          >
            <div
              className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}
            >
              <strong>{popupData?.features[0]?.properties?.libelle}</strong>
            </div>
            <div className="mapboxgl-popup-container">
              <div>
                <strong>Code Ligne: </strong>
                {popupData?.features[0]?.properties?.code_ligne}
              </div>
              <div>
                <strong>PK: </strong>
                {popupData?.features[0]?.properties?.PK}
              </div>
              <div>
                <strong>Code CH: </strong>
                {popupData?.features[0]?.properties?.code_CH}
              </div>
              <div>
                <strong>Code CI: </strong>
                {popupData?.features[0]?.properties?.code_CI}
              </div>
            </div>
          </MapPopup>
        )}
      </ReactMapGL>

      {params.operation_type === "mtbf" && (
        <div id="state-legend" className="legend">
          {widgetData?.percentiles &&
            widgetData?.percentiles.map((p) => (
              <div key={nextId()}>
                <span
                  style={{ backgroundColor: statusColorMtbf(p.percent) }}
                ></span>
                &#60; {round2(p.limit)}
              </div>
            ))}
        </div>
      )}

{params.operation_type === ("count" || "average" || "variance" || "max") && (
        <div id="state-legend" className="legend">
          {widgetData?.percentiles &&
            widgetData?.percentiles.map((p) => (
              <div key={nextId()}>
                <span
                  style={{ backgroundColor: statusColor(p.percent) }}
                ></span>
                &#60; {round2(p.limit)}
              </div>
            ))}
        </div>
      )}

      <div className="features d-flex flex-column" id="features">
        <div>Type de données: {params.data}</div>
        <div>Type d'opération: {params.operation_type}</div>
        <div>
          Période: du {params.start_date} au {params.end_date}
        </div>
        <div>Échelle: {params.spatial_scale}</div>
      </div>
      <div className="credits" id="credits">
        <div>DGEXSOL</div>
      </div>
      {showLoader && (
        <div className="dataLoader" id="dataLoader">
          <div>Chargement des données...</div>
        </div>
      )}
      {loadingError && (
        <div className="dataLoader" id="dataLoader">
          <div>Erreur lors du chargement des données...</div>
        </div>
      )}

      <div className="delete" id="delete">
        <button
          type="button"
          className="btn-rounded btn-rounded-white"
          onClick={() => deleteWidgetFromDashboard(params)}
        >
          <i className="icons-close" aria-hidden="true" />
        </button>
      </div>
      <div className="toggles" id="toggles">
        <button
          type="button"
          className={`mt-4 btn btn-sm ml-3 ${
            showPrsLayer === "visible" ? "btn-primary" : "btn-light"
          }`}
          onClick={togglePRs}
        >
          PRs
        </button>
        <button
          type="button"
          className={`mt-4 btn btn-sm ml-3 ${
            showLines ? "btn-primary" : "btn-light"
          }`}
          onClick={toggleLines}
        >
          Lignes
        </button>
        <button
          type="button"
          className={`mt-4 btn btn-sm ml-3 ${
            showIncidentsLayer === "visible" ? "btn-primary" : "btn-light"
          }`}
          onClick={toggleIncidents}
        >
          Incidents
        </button>
      </div>
    </>
  );
}

export default Widget;
