import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import MastNavItemSNCF from 'common/BootstrapSNCF/MastNavItemSNCF'
import MastNavSNCF from 'common/BootstrapSNCF/MastNavSNCF'
import NavBarSNCF from 'common/BootstrapSNCF/NavBarSNCF'
import Dashboard from 'views/Dashboard'
import Armen from 'views/Armen'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'

const DASHBOARD_PATH = '/dashboard'
const ARMEN_PATH = '/armen'

const HomeIncident = () => (
  <>
    <MastNavSNCF
      items={(
        <>
          <MastNavItemSNCF
            link={DASHBOARD_PATH}
            linkname="MTBF"
            icon="icons-warning"
          />
          <MastNavItemSNCF
            link={ARMEN_PATH}
            linkname="Armen"
            icon="icons-distribution"
          />
        </>
    )}
    />
    <NavBarSNCF appName="Observatoire de l'exploitation" logo={logo} />
    <main className="mastcontainer bg-white">
      <Switch>
        <Route exact path={DASHBOARD_PATH}>
          <Dashboard />
        </Route>
        <Route path={ARMEN_PATH}>
          <Armen />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    </main>
  </>
)

export default HomeIncident
