import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const applications = [
  {
    name: 'Observatoire',
    link: '/dashboard',
  },
  {
    name: 'PAA',
    link: '/PAA/home',
  },
]

const Home = () => (
  <div className="zonescontainer">
    <Row className="p-4 justify-content-center">
      {applications.map(application => (
        <Col key={application.name} className="my-2 col-sm-6 col-md-3 col-lg-2 mb-2">
          <Link className="card" to={application.link}>
            <Card className="hvr-fade">
              <Card.Body style={{ width: '8rem', height: '10rem' }} className="d-flex align-self-center align-items-center justify-content-center">
                <Card.Title>{application.name}</Card.Title>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  </div>
)

export default Home
