import React, { useEffect } from 'react'
import {
  Button, Col, Form, InputGroup,
} from 'react-bootstrap'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getItinerariesList, patchWidget, updateApplyParams, updateEndDate, updateItinerariesFilter,
  updateShowFilters,
  updateStartDate, updateTrainsTypesFilter,
} from 'reducers/paa'
import { useTranslation } from 'react-i18next'
import Select from 'common/BootstrapSNCF/SelectSNCF'
import { DateRangePicker } from 'react-bootstrap-daterangepicker'
import { GoCalendar } from 'react-icons/go'
import moment from 'moment'
import { TrainsTypes } from '../const'
import 'bootstrap-daterangepicker/daterangepicker.css'

const FilterSidebar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    representedObject, activeZone, filteredItineraries, startDate, endDate,
    itinerariesFilter, trainsTypesFilter, widgets, activeDashboard,
  } = useSelector(state => state.paa)

  useEffect(() => {
    if (representedObject === 'itineraire') dispatch(getItinerariesList(activeZone))
  }, [])

  const handleApply = (event, picker) => {
    dispatch(updateStartDate(picker.startDate.format('YYYY-MM-DD')))
    dispatch(updateEndDate(picker.endDate.format('YYYY-MM-DD')))
  }

  const onParamsChange = () => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      filtered_objects: itinerariesFilter,
      train_type: trainsTypesFilter,
    }

    widgets.forEach(widget => dispatch(patchWidget(widget.dashboard, widget.ID_widget, params)))
    dispatch(updateApplyParams(true))
  }

  return (
    <div className="sidebar pl-0">
      <div className="d-flex justify-content-between">
        <h1 className="pl-2 pt-2" style={{ fontWeight: '300' }}>{t('Dashboard.params')}</h1>
        <Button size="sm" className="btn-transparent" style={{ color: 'black', height: '2%' }} onClick={() => dispatch(updateShowFilters(false))}><FaAngleDoubleRight /></Button>
      </div>
      <div className="d-flex flex-column justify-content-between" style={{ height: '90%' }}>
        <div>
          <Col className="pt-2">
            <DateRangePicker
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Annuler',
                  applyLabel: 'Valider',
                },
                showDropdowns: true,
              }}
              onApply={handleApply}
            >
              <Form.Group controlId="datePickerForm">
                <Form.Label>{t('Dashboard.date')}</Form.Label>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="input-form pr-2"
                    style={{ color: '#0088ce' }}
                    type="text"
                    readOnly
                    value={`${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text className="input-form calendar px-3"><GoCalendar /></InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </Form.Group>
            </DateRangePicker>
          </Col>

          {representedObject === 'itineraire' && (
          <Col className="pt-2">
            <Select
              label={t('Dashboard.itineraries')}
              options={filteredItineraries}
              multiple
              withSearch
              onChange={selectedValues => dispatch(updateItinerariesFilter(selectedValues))}
              selectId="it"
              defaultValues={itinerariesFilter}
            />
          </Col>
          )}
          <Col className="pt-2">
            <Select
              label={t('Dashboard.trainsTypes')}
              options={TrainsTypes}
              multiple
              onChange={selectedValues => dispatch(updateTrainsTypesFilter(selectedValues))}
              selectId="train"
              defaultValues={trainsTypesFilter}
            />
          </Col>
        </div>
        <div>
          <Col className="d-flex justify-content-center">
            <Button variant="primary" size="sm" onClick={onParamsChange} disabled={activeDashboard.isDefault}>
              <div>{t('Dashboard.applyButton')}</div>
            </Button>
          </Col>
        </div>
      </div>
    </div>
  )
}

export default FilterSidebar
