// @ts-nocheck
import React, { useEffect, Suspense } from 'react'
import { auth } from '@osrdata/app_core'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'

import Loader from 'common/Loader'
import 'App.scss'
import NavBarSNCF from 'common/BootstrapSNCF/NavBarSNCF'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'
import Zones from 'applications/PAA/components/Zones'
import HomePAA from 'applications/PAA/HomePAA'
import Home from 'views/Home'
import HomeIncident from 'applications/incident/views/HomeIncident'
import history from './history'

export default function App() {
  const dispatch = useDispatch()
  const { isLogged } = useSelector(state => state.user)
  const { activeZone } = useSelector(state => state.paa)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  return (
    <>
      <Suspense fallback={<Loader />}>
        {isLogged && (
        <Router history={history}>
          <NavBarSNCF appName="Usage du réseau" logo={logo} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/dashboard" component={HomeIncident} />
            <Route exact path="/armen" component={HomeIncident} />
            <Route exact path="/PAA/home" component={Zones} />
            <Route path={`/PAA/${activeZone}/:obj`} component={HomePAA} />
            <Redirect to="/" />
          </Switch>
        </Router>
        )}
      </Suspense>
    </>
  )
}
