/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { getDashboardList, getWidgetsList, patchWidget, deleteDashboard } from "reducers/app";
import "moment/locale/fr";
import { Responsive, WidthProvider } from "react-grid-layout";
import AddWidgetModal from "components/AddWidgetModal";
import AddDashboardModal from 'components/AddDashboardModal'

import "./Widget.scss";

const ResponsiveGridLayout = WidthProvider(Responsive);

import Widget from "components/Widget";

function WidgetList() {
  const dispatch = useDispatch();

  const {
    widgetList,
    widgetsList,
    dashboard,
    dashboards,
    widget,
    deletedWidget,
    deletedDashboard
  } = useSelector((state) => state.app);
  const [savedList, setSavedList] = useState([]);
  const [idDashboard, setIdDashboard] = useState();
  const [showDashboard, setShowashboard] = useState(false)
  

  const [unlock, setLock] = useState(true);
  const params = {
    isDraggable: unlock,
    isResizable: unlock,
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const decodedToken = jwtDecode(token);
    dispatch(getDashboardList(decodedToken.sub));
  }, [dashboard, deletedDashboard]);

  const getWidgets = (dashboardID) => {
    setIdDashboard(dashboardID);
    dispatch(getWidgetsList(dashboardID));
    setShowashboard(true)
  };

  useEffect(() => {
    dispatch(getWidgetsList(idDashboard));
  }, [widget, deletedWidget]);

  const toggleLock = () => {
    setLock(!unlock);
  };
  const onLayoutChange = (layout, placeholder) => {
    layout.map((layout) =>
      dispatch(patchWidget(idDashboard, layout.i, layout))
    );
  };
  
  const removeDashboard = (ID) => {
    setIdDashboard(null)
    dispatch(deleteDashboard(ID))
  }

  return (
    <>
    <div style={{width: "100%"}} >
          <div className="d-flex m-3">
          <AddDashboardModal></AddDashboardModal>

            <div>
              {dashboards && dashboards.map((dashboard) => (
                <button
                  key={dashboard.ID_dashboard}
                  type="button"
                  className={`btn btn-sm ml-3 ${
                    dashboard.ID_dashboard != idDashboard ? "btn-light" : "btn-primary"
                  }`}
                  onClick={() => getWidgets(dashboard.ID_dashboard)}
                >
                  {dashboard.name}
                </button>
              ))}
              {dashboards.length > 0 && idDashboard && (
                <button
                key={`delete${dashboard.ID_dashboard}`}
                type="button"
                className={`btn btn-sm ml-3 btn-outline-danger`}
                onClick={() => removeDashboard(idDashboard)}
              >
                Supprimer
              </button>
              )}
              
            </div>
            <div className="ml-auto">
              {idDashboard && (
                <AddWidgetModal dashboardID={idDashboard}></AddWidgetModal>
              )}

              {idDashboard && (
                <button
                  type="button"
                  className={`btn mr-3 btn-sm ${
                    unlock ? "btn-light" : "btn-primary"
                  }`}
                  onClick={toggleLock}
                >
                  Verrouiller le positionement
                </button>
              )}
            </div>
          </div>
          {showDashboard && (
            <ResponsiveGridLayout
            className="layout"
            {...params}
            autoSize
            onLayoutChange={onLayoutChange}
            measureBeforeMount={true}
            useCSSTransforms={true}  
          >
            {widgetsList && widgetsList.map((widget) => (
              <div key={widget.ID_widget} data-grid={widget.layout_position}>
                <Widget params={widget} />
              </div>
            ))}
          </ResponsiveGridLayout>
          )}
        
        </div>
    </>
  );
}

export default WidgetList;
