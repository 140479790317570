import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import MastNavItemSNCF from 'common/BootstrapSNCF/MastNavItemSNCF'
import MastNavSNCF from 'common/BootstrapSNCF/MastNavSNCF'
import NavBarSNCF from 'common/BootstrapSNCF/NavBarSNCF'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'
import { Redirect } from 'react-router-dom'
import { FaHome } from 'react-icons/fa'
import { GiRailway } from 'react-icons/gi'
import iconAdv from 'assets/icons/adv.svg'
import underConstructionimg from 'assets/pictos/underconstruction.svg'
import iconIti from 'assets/icons/itineraire.svg'
import DashboardPAA from './views/DashboardPAA'

const HomePAA = () => {
  const { activeZone } = useSelector(state => state.paa)

  function underConstruction() {
    return (
      <div className="d-flex flex-column align-items-center mt-5">
        <img src={underConstructionimg} alt="" width="150" />
        <h1 className="mt-3">En cours de construction</h1>
      </div>
    )
  }

  return (
    <>
      <MastNavSNCF
        items={(
          <>
            <MastNavItemSNCF link="/PAA/home" linkname="Home" icon={<FaHome />} />
            <MastNavItemSNCF link={`/PAA/${activeZone}/itineraire`} linkname="Itinéraires" icon={<img src={iconIti} alt="" />} />
            <MastNavItemSNCF link={`/PAA/${activeZone}/circulation`} linkname="Circulations" icon="icons-itinerary-train" />
            <MastNavItemSNCF link={`/PAA/${activeZone}/adv`} linkname="Appareils de voie" icon={<img src={iconAdv} alt="" />} />
            <MastNavItemSNCF link={`/PAA/${activeZone}/voieaquai`} linkname="Voies à quai" icon={<GiRailway />} />
            <MastNavItemSNCF link={`/PAA/${activeZone}/map`} linkname="Cartographie" icon="icons-localisation-pin" />
          </>
        )}
      />
      <NavBarSNCF appName="Usage du réseau : Paris gare de Lyon" logo={logo} />
      <main className="mastcontainer bg-white">
        <Switch>
          <Route exact path={`/PAA/${activeZone}/itineraire`}>
            <DashboardPAA />
          </Route>
          <Route exact path={`/PAA/${activeZone}/circulation`}>
            <DashboardPAA category="circulation" />
          </Route>
          <Route exact path={`/PAA/${activeZone}/adv`}>
            {underConstruction}
          </Route>
          <Route exact path={`/PAA/${activeZone}/voieaquai`}>
            {underConstruction}
          </Route>
          <Route exact path={`/PAA/${activeZone}/map`}>
            {underConstruction}
          </Route>
          <Redirect to="/PAA/home" />
        </Switch>
      </main>
    </>
  )
}

export default HomePAA
