import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import nextId from 'react-id-generator'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilterValue } from 'reducers/paa'

const SelectSNCF = ({
  label, options, multiple, onChange, withSearch, selectId, defaultValues,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    searchValue, activeDashboard, itinerariesList,
  } = useSelector(state => state.paa)
  const [isActive, setIsActive] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])

  useEffect(() => {
    if (defaultValues.length !== 0 && defaultValues.length !== options.length) {
      if (selectId === 'it') {
        if (options.length === itinerariesList.length) setSelectedValues(defaultValues)
      } else {
        setSelectedValues(defaultValues)
      }
    }
  }, [defaultValues])

  useEffect(() => {
    if (selectId === 'it') {
      if (options.length === itinerariesList.length) setSelectedValues(options)
    } else {
      setSelectedValues(options)
    }
  }, [activeDashboard])

  useEffect(() => {
    if (searchValue && selectId === 'it' && selectedValues.length === itinerariesList.length) {
      setSelectedValues([])
    }
  }, [searchValue])

  useEffect(() => {
    if (selectedValues.length === 0 && !searchValue) {
      if (selectId === 'it') {
        if (options.length === itinerariesList.length) setSelectedValues(options)
      } else {
        setSelectedValues(options)
      }
    }
  }, [options])

  const renderOptions = () => (
    <>
      {options.map(option => (
        <option
          value={option.id || option || option[0]}
          key={option.id || nextId()}
        >
          {option.label || option || option[0]}
        </option>
      ))}
    </>
  )

  const onCheckBoxClick = event => {
    if (event.target.id === `selectAll-${selectId}`) {
      const updatedValues = selectedValues.length === options.length ? [] : options
      setSelectedValues(updatedValues)
      const itemsList = []
      updatedValues?.forEach(value => (typeof value === 'string' ? itemsList.push(value) : itemsList.push(value[0])))
      onChange(itemsList)
    } else {
      let checkedValues = []
      selectedValues.forEach(value => checkedValues.push(value))
      const filteredOption = checkedValues.length !== 0 ? checkedValues.filter(value => (typeof value === 'string'
        ? value === event.target.id : value[0] === event.target.id)) : ''
      if (filteredOption.length !== 0) {
        checkedValues = checkedValues.filter(value => (typeof value === 'string'
          ? value !== event.target.id : value[0] !== event.target.id))
      } else {
        checkedValues.push((options.filter(option => (typeof option === 'string'
          ? option === event.target.id : option[0] === event.target.id)))[0])
      }

      setSelectedValues(checkedValues)
      dispatch(updateFilterValue(''))
      const itemsList = []
      checkedValues.forEach(value => (typeof value === 'string' ? itemsList.push(value) : itemsList.push(value[0])))
      onChange(itemsList)
    }
  }

  const renderCheckBoxOptions = optionsList => optionsList.slice(0, 20).map(option => {
    const checked = typeof option === 'string' ? !!selectedValues.find(value => value === option) : !!selectedValues.find(value => (typeof value === 'string' ? value : value[0]) === option[0])
    return (
      <div className="select-menu-item" role="listitem" key={option.id || nextId()}>
        <div key="custom-inline-checkbox" className="mb-3">
          <Form.Check
            custom
            inline
            label={option.label || option || option[0]}
            type="checkbox"
            id={option.label || option || option[0]}
            multiple
            onChange={e => onCheckBoxClick(e)}
            checked={checked}
          />
        </div>
      </div>
    )
  })

  const showMultiselectOptions = () => {
    setIsActive(!isActive)
    dispatch(updateFilterValue(''))
  }

  const renderMultiSelect = () => {
    const selectAllValue = selectId === 'it' ? 'Tous les itinéraires' : 'Tous les types'
    const filteredOptions = selectedValues.length !== options.length ? options.filter(op => (typeof op === 'string' ? !selectedValues.includes(op)
      : !selectedValues.includes(op[0]))) : options
    return (
      <div
        className={`select-improved ${isActive ? 'active' : ''}`}
        data-component="select-multiple"
      >
        <div className="select-control">
          <InputGroup className="mb-2" data-role="select-toggle">
            <Form.Control
              className="input-form"
              type="text"
              placeholder={t('common.select')}
              value={selectedValues.length === options.length && searchValue === '' ? selectAllValue : selectedValues}
              aria-controls="multiselecttoggle"
              aria-expanded={isActive}
              onClick={showMultiselectOptions}
              style={{ caretColor: 'rgba(0,0,0,0)' }}
            />
            <InputGroup.Prepend>
              <Button
                className={`btn-only-icon multi-select-button ${isActive ? 'active' : ''}`}
                size="sm"
                type="button"
                aria-expanded={isActive}
                aria-controls="multiselecttoggle"
                onClick={showMultiselectOptions}
                style={{ width: '3.1rem' }}
              >
                <i className="icons-arrow-down icons-size-x75" aria-hidden="true" />
              </Button>
            </InputGroup.Prepend>
          </InputGroup>
          <div
            id="multiselecttoggle"
            className="select-menu py-0"
            data-role="menu"
            style={{
              boxShadow: ' #000 0px 0px 1px', borderRadius: '0', height: '19rem',
            }}
          >
            <div className="select-group" data-role="group" data-id="0" role="list">
              <div className={`cell-inner small-cell-sidebar ${withSearch ? 'mb-4 mt-2' : 'p-0'}`} style={{ minHeight: '2.25rem' }}>
                {withSearch && (
                <InputGroup>
                  <Form.Control
                    className="input-form input-form-search"
                    type="text"
                    placeholder={t('common.search')}
                    value={searchValue}
                    onChange={e => dispatch(updateFilterValue(e.target.value))}
                  />
                  {searchValue && (
                  <InputGroup.Prepend>
                    <Button type="button" variant="primary" size="sm" className="btn-only-icon multi-select-button" onClick={() => dispatch(updateFilterValue(''))}>
                      <i className="icons-close icons-size-x5" aria-hidden="true" />
                    </Button>
                  </InputGroup.Prepend>
                  )}
                </InputGroup>
                )}
              </div>
              <div style={{ overflowY: 'auto', maxHeight: `${withSearch ? '13.5rem' : '15.5'}` }}>
                {!searchValue && (
                <div key="custom-inline-checkbox" className="mb-3">
                  <Form.Check
                    custom
                    inline
                    label="Tout sélectionner"
                    type="checkbox"
                    id={`selectAll-${selectId}`}
                    multiple
                    onChange={e => onCheckBoxClick(e)}
                    checked={selectedValues.length === options.length && searchValue === ''}
                  />
                </div>
                )}
                {selectedValues.length !== options.length && renderCheckBoxOptions(selectedValues)}
                {selectedValues.length !== options.length && selectedValues.length !== 0 && <div className="dropdown-divider mt-4 mb-4" />}
                {renderCheckBoxOptions(filteredOptions)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Form.Group controlId="FormSelect">
      <Form.Label>{label}</Form.Label>
      {!multiple && (
      <select
        className="select-form form-control form-control-sm"
        onChange={onChange}
      >
        <option value="default" selected disabled>
          {t('common.select')}
        </option>
        {renderOptions()}
      </select>
      )}
      {multiple && renderMultiSelect()}
    </Form.Group>
  )
}

SelectSNCF.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultValues: PropTypes.array,
  multiple: PropTypes.bool,
  withSearch: PropTypes.bool,
  onChange: PropTypes.func,
  selectId: PropTypes.string,
}

SelectSNCF.defaultProps = {
  defaultValues: [],
  multiple: false,
  withSearch: false,
  onChange: () => {},
  selectId: '',
}

export default SelectSNCF
