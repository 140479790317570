/* eslint-disable */

import React from 'react';
import { useSelector } from "react-redux";

import AddDashboardModal from 'components/AddDashboardModal'
import WidgetList from 'components/WidgetList'
import Alert from "react-bootstrap/Alert";

function Dashboard() {
  const { showLoader } = useSelector((state) => state.app);
  return (
    <> 
    {showLoader && <Alert className="alert-loading" variant="secondary">
    Chargement en cours ...
  </Alert>}
        <WidgetList></WidgetList>
    </>
  )
}

export default Dashboard
