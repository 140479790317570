/* eslint-disable default-case */
import { API_URLS } from 'applications/PAA/const'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import produce from 'immer'
import { filterBySearch } from 'utils/helpers'

export const GET_ERROR = 'GET_ERROR'
export const SHOW_LOADER = 'SHOW_LOADER'

export const SHOW_DASHBOARDS = 'SHOW_DASHBOARDS'
export const SHOW_FILTERS = 'SHOW_FILTERS'
export const GET_ZONES = 'mistral_old/GET_ZONES'
export const ACTIVE_ZONE = 'mistral_old/ACTIVE_ZONE'
export const REPRESENTED_OBJECT = 'mistral_old/REPRESENTED_OBJECT'
export const GET_DASHBOARDS = 'mistral_old/GET_DASHBOARDS'
export const POST_DASHBOARD = 'mistral_old/POST_DASHBOARD'
export const UPDATE_DASHBOARD = 'mistral_old/UPDATE_DASHBOARD'
export const ACTIVE_DASHBOARD = 'mistral_old/ACTIVE_DASHBOARD'
export const DELETE_DASHBOARD = 'mistral_old/DELETE_DASHBOARD'
export const GET_WIDGETS = 'mistral_old/GET_WIDGETS'
export const POST_WIDGET = 'mistral_old/POST_WIDGET'
export const PATCH_WIDGET = 'mistral_old/PATCH_WIDGET'
export const DELETE_WIDGET = 'mistral_old/DELETE_WIDGET'
export const GET_ITINERARIES = 'mistral_old/GET_ITINERARIES'
export const UPDATE_SEARCH_VALUE = 'mistral_old/UPDATE_SEARCH_VALUE'
export const UPDATE_FILTERED_ITINERARIES = 'mistral_old/UPDATE_FILTERED_ITINERARIES'
export const ITINERARIES_FILTER = 'mistral_old/ITINERARIES_FILTER'
export const TRAINS_TYPES_FILTER = 'mistral_old/TRAINS_TYPES_FILTER'
export const START_DATE = 'mistral_old/START_DATE'
export const END_DATE = 'mistral_old/END_DATE'
export const APPLY_PARAMS = 'mistral_old/APPLY_PARAMS'
export const DASHBOARD_CONTENT_WIDTH = 'mistral_old/DASHBOARD_CONTENT_WIDTH'

const initialState = {
  error: false,
  showLoader: false,
  showDashboards: true,
  showFilters: true,
  zones: [],
  activeZone: '',
  representedObject: 'itineraire',
  dashboards: [],
  dashboard: {},
  activeDashboard: {},
  deletedDashboard: '',
  widgets: [],
  widget: {},
  deletedWidget: '',
  itinerariesList: [],
  filteredItineraries: [],
  searchValue: '',
  trainsTypesFilter: [],
  itinerariesFilter: [],
  startDate: '',
  endDate: '',
  applyParams: false,
  dashboardContentWidth: 0,
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ERROR:
        draft.error = action.error
        break
      case SHOW_LOADER:
        draft.showLoader = action.showLoader
        break
      case SHOW_DASHBOARDS:
        draft.showDashboards = action.showDashboards
        break
      case SHOW_FILTERS:
        draft.showFilters = action.showFilters
        break
      case GET_ZONES:
        draft.zones = action.zones
        break
      case ACTIVE_ZONE:
        draft.activeZone = action.activeZone
        break
      case REPRESENTED_OBJECT:
        draft.representedObject = action.representedObject
        break
      case GET_DASHBOARDS:
        draft.dashboards = action.dashboards
        break
      case POST_DASHBOARD:
        draft.dashboard = action.dashboard
        break
      case UPDATE_DASHBOARD:
        draft.dashboard = action.dashboard
        break
      case ACTIVE_DASHBOARD:
        draft.activeDashboard = action.activeDashboard
        break
      case DELETE_DASHBOARD:
        draft.deletedDashboard = action.deletedDashboard
        break
      case GET_WIDGETS:
        draft.widgets = action.widgets
        break
      case POST_WIDGET:
        draft.widget = action.widget
        break
      case PATCH_WIDGET:
        draft.widget = action.widget
        break
      case DELETE_WIDGET:
        draft.deletedWidget = action.deletedWidget
        break
      case GET_ITINERARIES:
        draft.itinerariesList = action.itinerariesList
        break
      case UPDATE_SEARCH_VALUE:
        draft.searchValue = action.searchValue
        break
      case UPDATE_FILTERED_ITINERARIES:
        draft.filteredItineraries = action.filteredItineraries
        break
      case START_DATE:
        draft.startDate = action.startDate
        break
      case END_DATE:
        draft.endDate = action.endDate
        break
      case ITINERARIES_FILTER:
        draft.itinerariesFilter = action.itinerariesFilter
        break
      case TRAINS_TYPES_FILTER:
        draft.trainsTypesFilter = action.trainsTypesFilter
        break
      case APPLY_PARAMS:
        draft.applyParams = action.applyParams
        break
      case DASHBOARD_CONTENT_WIDTH:
        draft.dashboardContentWidth = action.dashboardContentWidth
        break
    }
  })
}

export const updateShowDashboards = show => dispatch => {
  dispatch({
    type: SHOW_DASHBOARDS,
    showDashboards: show,
  })
}

export const updateShowFilters = show => dispatch => {
  dispatch({
    type: SHOW_FILTERS,
    showFilters: show,
  })
}

export const getZones = () => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const { results: zones } = await get(API_URLS.zones)
    dispatch({
      type: GET_ZONES,
      zones,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return zones
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const updateActiveZone = activeZone => async dispatch => {
  dispatch({
    type: ACTIVE_ZONE,
    activeZone,
  })
}

export const updateRepresentedObject = representedObject => async dispatch => {
  dispatch({
    type: REPRESENTED_OBJECT,
    representedObject,
  })
}

export const getDashboards = params => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const { results: dashboards } = await get(API_URLS.dashboards, params)
    dispatch({
      type: GET_DASHBOARDS,
      dashboards,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return dashboards
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const postDashboard = params => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const dashboard = await post(API_URLS.dashboards, params)
    dispatch({
      type: POST_DASHBOARD,
      dashboard,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return dashboard
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const patchDashboard = (dashboardID, params) => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      showLoader: true,
    })
    const dashboard = await patch(`${API_URLS.dashboards}${dashboardID}/`, params)
    dispatch({
      type: UPDATE_DASHBOARD,
      dashboard,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return dashboard
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const setActiveDashboard = dashboard => async dispatch => {
  dispatch({
    type: ACTIVE_DASHBOARD,
    activeDashboard: dashboard,
  })
}

export const deleteDashboard = dashboardID => async dispatch => {
  try {
    const deletedDashboard = await deleteRequest(
      `${API_URLS.dashboards}${dashboardID}/`,
    )
    dispatch({
      type: DELETE_DASHBOARD,
      deletedDashboard: dashboardID,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return deletedDashboard
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    dispatch({
      type: SHOW_LOADER,
      showLoader: false,
    })
    return error
  }
}

export const getWidgets = dashboardID => async dispatch => {
  try {
    const { widgets_list: widgets } = await get(
      `${API_URLS.dashboards}${dashboardID}/`,
    )
    dispatch({
      type: GET_WIDGETS,
      widgets,
    })
    return widgets
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}

export const postWidget = (dashboardID, params) => async dispatch => {
  try {
    const widget = await post(
      `${API_URLS.dashboards}${dashboardID}/widgets/`,
      params,
    )
    dispatch({
      type: POST_WIDGET,
      widget,
    })
    return widget
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}

export const patchWidget = (dashboardID, widgetID, params,
  onlyLayout = false) => async dispatch => {
  try {
    const widget = await patch(
      `${API_URLS.dashboards}${dashboardID}/widgets/${widgetID}/`,
      params,
    )
    if (!onlyLayout) {
      dispatch({
        type: PATCH_WIDGET,
        widget,
      })
    }

    return widget
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}

export const deleteWidget = (dashboardID, widgetID) => async dispatch => {
  try {
    const deletedWidget = await deleteRequest(
      `${API_URLS.dashboards}${dashboardID}/widgets/${widgetID}/`,
    )
    dispatch({
      type: DELETE_WIDGET,
      deletedWidget: widgetID,
    })
    return deletedWidget
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}

const updateFilteredItems = () => (dispatch, getState) => {
  const { paa } = getState()
  const { searchValue, itinerariesList } = paa

  let filteredItineraries = itinerariesList
  if (searchValue !== '') {
    filteredItineraries = filterBySearch(filteredItineraries, searchValue)
  }
  dispatch({
    type: UPDATE_FILTERED_ITINERARIES,
    filteredItineraries,
  })
}

export const updateFilterValue = searchValue => dispatch => {
  dispatch({
    type: UPDATE_SEARCH_VALUE,
    searchValue,
  })
  dispatch(updateFilteredItems())
}

export const getItinerariesList = zone => async dispatch => {
  try {
    const params = {
      metric: 'liste_itineraires',
      zone,
    }
    const itinerariesList = await get(`${API_URLS.itineraire}`, params)
    dispatch({
      type: GET_ITINERARIES,
      itinerariesList,
    })
    dispatch(updateFilteredItems())
    return itinerariesList
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}

export const updateStartDate = startDate => dispatch => {
  dispatch({
    type: START_DATE,
    startDate,
  })
}

export const updateEndDate = endDate => dispatch => {
  dispatch({
    type: END_DATE,
    endDate,
  })
}

export const updateItinerariesFilter = itineraries => dispatch => {
  dispatch({
    type: ITINERARIES_FILTER,
    itinerariesFilter: itineraries,
  })
}

export const updateTrainsTypesFilter = trainsTypes => dispatch => {
  dispatch({
    type: TRAINS_TYPES_FILTER,
    trainsTypesFilter: trainsTypes,
  })
}

export const updateApplyParams = applyParams => dispatch => {
  dispatch({
    type: APPLY_PARAMS,
    applyParams,
  })
}

export const updateDashboardContentWidth = width => async dispatch => {
  try {
    dispatch({
      type: DASHBOARD_CONTENT_WIDTH,
      dashboardContentWidth: width,
    })
    return null
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    })
    return error
  }
}
