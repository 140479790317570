import React from 'react'
import {
  Col, Row, Button, Card, DropdownButton, Alert,
} from 'react-bootstrap'
import { FaAngleDoubleLeft, FaUpload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveDashboard, updateShowDashboards } from 'reducers/paa'
import { AiOutlineMore } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import DeleteDashboardModal from './DeleteDashboardModal'
import { defaultDashboards } from '../const'
import AddDashboardModal from './AddDashboardModal'

const DashboardSidebar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    dashboards, representedObject, showLoader, activeDashboard,
  } = useSelector(state => state.paa)

  const dashboardsList = defaultDashboards[representedObject].concat(dashboards)

  return (
    <>
      {showLoader && (
      <Alert className="alert-loading" variant="secondary">
        {t('common.loading')}
      </Alert>
      )}
      <div className="sidebar">
        <div className="d-flex justify-content-between">
          <h1 className="pl-2 pt-2" style={{ fontWeight: '300' }}>{t('Dashboard.dashboard')}</h1>
          <Button size="sm" className="btn-transparent" style={{ color: 'black', height: '2%' }} onClick={() => dispatch(updateShowDashboards(false))}><FaAngleDoubleLeft /></Button>
        </div>
        <div className="d-flex flex-column justify-content-between" style={{ height: '92%' }}>
          <Row xs={1} md={2} className="py-2 pl-3 mr-0 pr-0" style={{ maxHeight: '75%', overflowY: 'auto' }}>
            {dashboardsList.map(dashboard => (
              <Col key={dashboard.ID_dashboard} className={`pr-0 pt-2 ${activeDashboard.name === dashboard.name ? 'active-card' : ''}`} style={{ flexGrow: '0' }}>
                <Card
                  className="text-center p-0 card-button"
                  style={{
                    height: '7rem',
                    width: '7rem',
                  }}
                  as="button"
                  onClick={() => dispatch(setActiveDashboard(dashboard))}
                >
                  <div
                    style={{
                      width: '7rem',
                    }}
                    className="d-flex justify-content-end"
                  >
                    {!dashboard.isDefault ? (
                      <DropdownButton variant="outline-light" size="sm" className="px-1" title={<AiOutlineMore />} onClick={e => e.stopPropagation()}>
                        <DeleteDashboardModal dashboard={dashboard} />
                        <AddDashboardModal
                          defaultValue={dashboard}
                        />
                      </DropdownButton>
                    ) : <div style={{ height: '1.35rem' }} />}
                  </div>

                  <Card.Body
                    className="p-0 d-flex align-items-center flex-column"
                    style={{
                      height: '7rem', width: '7rem', minHeight: '0', minWidth: '0', borderRadius: '.4375rem',
                    }}
                  >
                    {dashboard.icon !== null ? (
                      <Card.Img
                        className="dashboard-icon mt-1"
                        style={{ height: '1.5rem', width: '1.5rem' }}
                        variant="top"
                        src={dashboard.icon}
                      />
                    ) : <div style={{ height: '1.5rem', width: '1.5rem' }} />}
                    <Card.Text className="text-center mt-2">{dashboard.name}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <Col className="pr-0 mb-2 pt-2" style={{ flexGrow: '0' }}>
              <AddDashboardModal />
            </Col>
          </Row>
          <div className="d-flex justify-content-center my-3">
            <Button variant="primary" size="sm" className="d-flex justify-content-between">
              <div className="pr-1"><FaUpload /></div>
              <div style={{ marginTop: '.1rem' }}>{t('Dashboard.exportButton')}</div>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardSidebar
