import React from 'react'
import PropTypes from 'prop-types'
import { DropdownButton } from 'react-bootstrap'
import { AiOutlineMore } from 'react-icons/ai'
import DeleteWidgetModal from './DeleteWidgetModal'
import UpdateWidgetNameModal from './UpdateWidgetNameModal'

const WidgetTools = ({ widget }) => (
  <>
    <DropdownButton variant="outline-light" size="sm" className="px-1" title={<AiOutlineMore />}>
      <DeleteWidgetModal widgetID={widget.ID_widget} />
      <UpdateWidgetNameModal defaultValue={widget} />
    </DropdownButton>
  </>
)

WidgetTools.propTypes = {
  widget: PropTypes.object.isRequired,
}
export default WidgetTools
