import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'
import { Card, Carousel } from 'react-bootstrap'
import WidgetTools from './WidgetTools'
import { weekDays } from '../const'

const Line = ({
  data, header, widget, isDefault, buttomAxiosLegend,
}) => {
  const { representedObject } = useSelector(state => state.paa)
  const [index, setIndex] = useState(0)

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const getLine = dataList => (
    <Card.Body className={`h-100 ${representedObject === 'itineraire' ? 'p-0' : ''}`}>
      <ResponsiveLine
        data={dataList}
        margin={{
          top: 40, right: 50, bottom: 60, left: 60,
        }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: buttomAxiosLegend,
          legendOffset: 36,
          legendPosition: 'middle',
          format: representedObject === 'itineraire' ? value => weekDays.find(day => day.id === value).label : '',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Moyenne de circulations',
          legendOffset: -50,
          legendPosition: 'middle',
        }}
        enableGridX
        enableGridY
        colors={{ scheme: 'spectral' }}
        pointSize={10}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        pointSymbol={({
          size, color, borderWidth, borderColor,
        }) => (
          <g>
            <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
            <circle
              r={size / 5}
              strokeWidth={borderWidth}
              stroke={borderColor}
              fill={color}
              fillOpacity={0.35}
            />
          </g>
        )}
        pointLabelYOffset={-12}
        useMesh
        tooltip={value => (
          <div className="graphs-tooltip">
            <div style={{ backgroundColor: value.point.serieColor, width: '.75rem', height: '.75rem' }} />
            <div className="ml-2" style={{ color: 'black' }}>
              {value.point.serieId}
              :
              <strong className="ml-1">{value.point.data.yFormatted}</strong>
            </div>
          </div>
        )}
      />
    </Card.Body>
  )

  return (
    <Card className="widget-card">
      <Card.Header className="d-flex justify-content-between pr-2">
        {header}
        {!isDefault && <WidgetTools widget={widget} />}
      </Card.Header>
      {representedObject === 'itineraire'
        ? (
          <Carousel className="h-100" activeIndex={index} onSelect={handleSelect} interval={null}>
            {data.slice(0, 3).map(d => (
              <Carousel.Item key={d.id} className="h-100 pt-2">
                <span className="pl-4">{`Itinéraire: ${d.itineraire_jalon}`}</span>
                <Carousel.Caption>
                  {getLine(d.data)}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        )
        : getLine(data)}
    </Card>
  )
}

Line.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  isDefault: PropTypes.bool.isRequired,
  buttomAxiosLegend: PropTypes.string.isRequired,
}

export default Line
