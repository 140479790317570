import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { Card } from 'react-bootstrap'
import WidgetTools from './WidgetTools'

const Histogram = ({
  header, data, keys, buttomAxiosLegend, leftAxiosLegend, colorBy, widget, isDefault,
}) => (
  <Card className="widget-card">
    <Card.Header className="d-flex justify-content-between pr-2">
      {header}
      {!isDefault && <WidgetTools widget={widget} />}
    </Card.Header>
    <Card.Body style={{ height: '250px' }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="key"
        margin={{
          top: 0, right: 20, bottom: 50, left: 110,
        }}
        layout="horizontal"
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'blues' }}
        colorBy={colorBy}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `${buttomAxiosLegend}`,
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `${leftAxiosLegend}`,
          legendPosition: 'middle',
          legendOffset: -100,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#b6b3b3"
        tooltip={({
          id, value, color, label,
        }) => (
          <div className="graphs-tooltip">
            <div style={{ backgroundColor: color, width: '.75rem', height: '.75rem' }} />
            <div className="ml-2">
              {id === value ? label : label.replace('value - ', '')}
              :
              <strong className="ml-1">{value}</strong>
            </div>
          </div>
        )}
        theme={{
          fontSize: 12,
          axis: {
            legend: {
              text: {
                fontSize: 15,
              },
            },
          },
          labels: {
            text: {
              fontSize: 13,
            },
          },
        }}
      />
    </Card.Body>
  </Card>
)

Histogram.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  buttomAxiosLegend: PropTypes.string.isRequired,
  leftAxiosLegend: PropTypes.string.isRequired,
  colorBy: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  isDefault: PropTypes.bool.isRequired,
}

export default Histogram
