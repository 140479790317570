import Loader from 'common/Loader'
import React, { useEffect } from 'react'
import {
  Card, Col, Row, Fade,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getZones, updateActiveZone } from 'reducers/paa'

const Zones = () => {
  const dispatch = useDispatch()
  const { zones, showLoader } = useSelector(state => state.paa)

  useEffect(() => { dispatch(getZones()) }, [])

  return (
    <div className="zonescontainer">
      <div className="d-flex justify-content-center mb-4"><strong>PAA</strong></div>
      {showLoader && <Loader center />}
      <Fade in={!showLoader}>
        <Row className="justify-content-center">
          {zones.map(zone => (
            <Col key={zone.abreviation} className="col-6 col-sm-4 col-md-3 col-lg-2">
              <Link to={`/PAA/${zone.abreviation}/itineraire`} onClick={() => dispatch(updateActiveZone(zone.abreviation))}>
                <Card style={{ height: '10rem' }} className={`${zone.abreviation === 'PLY' ? 'hvr-fade' : 'disabled-zone'}`}>
                  <Card.Body className="d-flex justify-content-center align-items-center text-center">
                    <Card.Title>{zone.libelle}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Fade>
    </div>
  )
}

export default Zones
