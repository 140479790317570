import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { GiTrashCan } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { deleteWidget } from 'reducers/paa'
import { useTranslation } from 'react-i18next'

const DeleteWidgetModal = ({ widgetID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeDashboard } = useSelector(state => state.paa)
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const removeWidget = () => {
    dispatch(deleteWidget(activeDashboard.ID_dashboard, widgetID))
    handleClose()
  }

  return (
    <>
      <Button size="sm" className="btn-transparent mx-1" style={{ color: 'red', minWidth: '0.875rem', minHeight: '0.875rem' }} onClick={handleShow}>
        <GiTrashCan />
      </Button>
      <Modal
        show={show}
        onExited={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center">
          <Modal.Title className="w-100">
            <h1>
              {t('modals.deleteWidget')}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {t('modals.cancelButton')}
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={removeWidget}
          >
            {t('modals.deleteButton')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

DeleteWidgetModal.propTypes = {
  widgetID: PropTypes.string.isRequired,
}

export default DeleteWidgetModal
